import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '@/plugins/app/_theme/theme.sass';

// wezeo plugins
import ionic from '@/plugins/w/ionic'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(ionic)

// @ts-ignore
import Client from '@doclify/javascript/src/index.js'

app.config.globalProperties.$doclify = new Client({
	url: 'https://homenewssk.cdn.doclify.io/api/v2',
	repository: 'https://homenewssk.cdn.doclify.io',
	token: 'DT2mkJ9cZY2kFXc63C8PDDUihqyVBeLC',
	lang: 'en',
	timeout: 10000
})

  
router.isReady().then(() => {
  app.mount('#app');
});