import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/plugins/app/views/home.vue')
	},
	{
		path: '/osr/terms-of-services',
		name: 'OSR Terms of services',
		component: () => import('@/plugins/app/views/osr-terms-of-services.vue')
	},
	{
		path: '/homenews/terms-of-services',
		name: 'HomeNews Terms of services',
		component: () => import('@/plugins/app/views/homenews-terms-of-services.vue')
	}
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
